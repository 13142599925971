import {slider} from './slider';

const url = location.href
const copyUrl = new URL(url)
const headlineText = document.getElementById('headlineText')
const headline = copyUrl.searchParams.get('headline')
if (headline) {
  headlineText.innerText = headline
}


slider()

const MENU_BTN = document.querySelector('.menu-btn')
const MENU = document.querySelector('.header__navigation')
const CLOSE_MENU = document.querySelector('.menu-close')
const DELIVERY_BTN = document.querySelector('.delivery-btn')
const SERVICE_BTN = document.querySelector('.service-btn')
const DELIVERY_BANNER = document.querySelector('.delivery-banner')
const SERVICE_BANNER = document.querySelector('.service-banner')
const BANNER_CLOSE = document.querySelectorAll('.banner-btn')
const CATALOG_TITLE = document.querySelector('.catalog__title')
const CATALOG_BTN = document.querySelector('.catalog__btn')
const CATALOG = document.querySelector('.catalog__list-wrapper')
const CATALOG_ITEM = document.querySelectorAll('.openCardProduct')
const CATALOG_CARD = document.querySelector('.card')
const MODAL_BG = document.querySelector('.modal-bg')
const MODAL = document.querySelector('.modal')
const MORE_BTN = document.querySelector('.description-more')

const cardTitle = document.querySelector('.card__headline-title')
const descTitle = document.querySelector('.card__description-title')
const cardDesc = document.querySelector('.card__description-text')
const cardLink = document.querySelector('.card__description-link')
const cardImg = document.querySelector('.card__headline-img')
const orderProductBtn = document.querySelector('.orderProductBtn')


//Открытие\Закрытие меню
MENU_BTN.addEventListener('click', () => {
  toggleMenu()
})

CLOSE_MENU.addEventListener('click', () => {
  closeMenu()
})


function toggleMenu() {
  MENU_BTN.classList.toggle('active')
  MENU.classList.toggle('active')
}

function closeMenu() {
  MENU.classList.remove('active')
  MENU_BTN.classList.remove('active')
}

//Открытие\Закрытие меню

//Переключение главного экрана
DELIVERY_BTN.onclick = function () {
  DELIVERY_BANNER.classList.toggle('active')
  SERVICE_BANNER.classList.remove('active')
}

SERVICE_BTN.onclick = function () {
  SERVICE_BANNER.classList.toggle('active')
  DELIVERY_BANNER.classList.remove('active')
}

BANNER_CLOSE.forEach((item) => (
  item.onclick = function () {
    SERVICE_BANNER.classList.remove('active')
    DELIVERY_BANNER.classList.remove('active')
  })
)

//Раскрытие каталога
CATALOG_TITLE.onclick = function () {
  CATALOG.classList.toggle('active')
}

CATALOG_BTN.onclick = function () {
  CATALOG.classList.toggle('active')
  CATALOG_BTN.classList.toggle('active')
}


CATALOG_ITEM.forEach((item) => {
  item.addEventListener('click', () => {
    CATALOG_CARD.classList.add('active')
    CATALOG.style.display = 'none'
    cardTitle.innerText = item.getAttribute('data-title')
    descTitle.innerText = item.getAttribute('data-brand')
    cardDesc.innerText = item.getAttribute('data-desc')
    cardLink.href = item.getAttribute('data-link')
    cardImg.src = item.getAttribute('data-image')
    orderProductBtn.setAttribute('data-product', item.getAttribute('data-title'))
    location.href = location.href.replace(location.hash, '') + '#card'
  })
})

//Открытие\Закрытие модальных окон + Генерация контента карточки
window.addEventListener('click', (event) => {

  let element = event.target

  if (element.classList.contains('card-close')) {
    CATALOG_CARD.classList.remove('active')
    cardDesc.classList.remove('active')
    CATALOG.style.display = 'flex'
  }

  if (element.classList.contains('description-more')) {
    cardDesc.classList.toggle('active')
    MORE_BTN.classList.toggle('active')
  }

  if (element.classList.contains('modal-close') ||
    (element.classList.contains('modal-bg'))) {
    MODAL.classList.remove('active')
    MODAL_BG.classList.remove('active')
  }
})


// Работа с модальными окнами
const modalBg = document.querySelector('.modal-bg')
const modalWindows = document.querySelectorAll('.modal')
const closeBtn = document.querySelectorAll('.modal-close')
const modalCloseBtn = document.querySelectorAll('.modalCloseBtn')
const mainModalHeadline = document.querySelector('.mainModalHeadline')
const productName = document.getElementById('productName')

const openModalBtn = document.querySelectorAll('.openModal')
if (openModalBtn) {
  openModalBtn.forEach((item) => {
    item.addEventListener('click', () => {
      const typeModal = item.getAttribute('data-type')
      mainModalHeadline.innerText = item.getAttribute('data-headline')
      if (item.getAttribute('data-product')) {
        productName.value = item.getAttribute('data-product')
      } else {
        productName.value = 'Заявка из формы'
      }
      openModal(typeModal)
    })
  })
}

function closeModal() {
  modalBg.classList.remove('active')
  modalWindows.forEach((item) => {
    item.classList.remove('active')
  })
  clearFileInput()
}

closeBtn.forEach((item) => {
  item.addEventListener('click', () => {
    closeModal()
  })
})

modalCloseBtn.forEach((item) => {
  item.addEventListener('click', () => {
    closeModal()
  })
})

modalBg.addEventListener('click', () => {
  closeModal()
})

function openModal(typeModal) {
  modalBg.classList.add('active')
  modalWindows.forEach((item) => {
    if (item.classList.contains(typeModal)) {
      item.classList.add('active')
    } else {
      item.classList.remove('active')
    }
  })
}


//Маска ввода телефона
$(document).ready(function () {
  $(".phone-mask").mask("9 (999) 999-99-99");
});


// Подстановка имени файла
const fileName = document.querySelectorAll('.fileName')
const inputFiles = document.querySelectorAll('.inputFile')
let currentTitleForFile = fileName[0].innerText

inputFiles.forEach((item) => {
  item.addEventListener('change', () => {
    let fileNameInput = item.value.replace(/.*\\/, "")
    setFileInputName(fileNameInput)
  })
})

function setFileInputName(fileNameInput) {
  fileName.forEach((item) => {
    item.innerText = fileNameInput
  })
}

function clearFileInput() {
  fileName.forEach((item) => {
    item.innerText = currentTitleForFile
  })
  inputFiles.forEach((item) => {
    item.value = ''
  })
}


// Формирование формы аналитики
$(document).ready(function () {
  let url_string = location.href;
  let url = new URL(url_string);
  let utm_source = url.searchParams.get('utm_source');
  let utm_medium = url.searchParams.get('utm_meduim');
  let utm_compaign = url.searchParams.get('utm_compaign');
  let utm_content = url.searchParams.get('utm_content');
  let utm_term = url.searchParams.get('utm_term');
  let yclid = url.searchParams.get('yclid');
  let gclid = url.searchParams.get('gclid');
  let clientid;
  let googlecid;

  $('.analytics input[name="utm_source"]').val(utm_source);
  $('.analytics input[name="utm_medium"]').val(utm_medium);
  $('.analytics input[name="utm_compaign"]').val(utm_compaign);
  $('.analytics input[name="utm_content"]').val(utm_content);
  $('.analytics input[name="utm_term"]').val(utm_term);
  $('.analytics input[name="yclid"]').val(yclid);
  $('.analytics input[name="gclid"]').val(gclid);

  if (document.cookie.search('(?:^|;)\\s*_ga=([^;]*)') !== -1) {
    googlecid = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
    $('.analytics input[name="googlecid"]').val(googlecid[0].slice(10));
  } else {
    googlecid = 'GA - отсуствует'
    $('.analytics input[name="googlecid"]').val(googlecid);
  }

  if (document.cookie.search('(?:^|;)\\s*_ym_uid=([^;]*)') !== -1) {
    clientid = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)');
    $('.analytics input[name="clientid"]').val(clientid[1]);
  } else {
    clientid = 'YM - отсуствует'
    $('.analytics input[name="clientid"]').val(clientid);
  }

});


//Передача лида и формирование формы аналитики
$(function () {
  $('form').submit(function (e) {
    e.preventDefault();
    let $form = $(this),
      formID,
      formData = new FormData(this);
    $("form#analytics").serializeArray().forEach(function (field) {
      formData.append(field.name, field.value)
    });
    formID = "#" + $form.attr('id') + " button";  // Формируем переменную с номер формы и добавляем селектор button
    $(formID).prop('disabled', true);
    $(formID).css({'opacity': 0.3});
    $.ajax({
      url: $form.attr('action'),
      type: $form.attr('method'),
      data: formData,
      cache: false,
      contentType: false,
      processData: false
    }).done(function () {
      $('form').trigger('reset');
      $(formID).prop('disabled', false);
      $(formID).css({'opacity': 1});
      clearFileInput()
      closeModal()
      openModal('modal-send')
    });
  });
});



